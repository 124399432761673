export default [
    {
        path: '/pc',
        name: 'pc',
        redirect: {
            name: 'index'
        },
        components: {
            default: () => import('@/views/PC/PcAll/PcAll'),
        },
        children: [
            {
                path: 'home',
                name: 'pcHome',
                components: {
                    default: () => import('@/views/PC/HomePage/HomePage'),
                },
                children: [
                    {
                        path: 'index',
                        name: 'index',
                        components: {
                            default: () => import('@/views/PC/IndexPage/IndexPage'),
                        },
                    },
                    {
                        path: 'shopDetail',
                        name: 'pcShopDetail',
                        components: {
                            default: () => import('@/views/PC/ShopDetail/ShopDetail'),
                        },
                    },
                    {
                        path: 'shopCart',
                        name: 'shopCart',
                        components: {
                            default: () => import('@/views/PC/ShopCart/ShopCart'),
                        },
                    },
                    {
                        path: 'pcPay',
                        name: 'pcPay',
                        components: {
                            default: () => import('@/views/PC/pcPay/pcPay'),
                        },
                    },
                    // 支付宝支付
                    {
                        path: 'pcAlipay',
                        name: 'pcAlipay',
                        components: {
                            default: () => import('@/views/PC/pcAlipay/pcAlipay'),
                        },
                    },
                    
                    {
                        path: 'pcAppraise',
                        name: 'pcAppraise',
                        components: {
                            default: () => import('@/views/PC/pcAppraise/pcAppraise'),
                        },
                    },
                    {
                        path: 'orderDetails',
                        name: 'orderDetails',
                        components: {
                            default: () => import('@/views/PC/myBuy/orderDetails'),
                        },
                        
                    },
                    {
                        path: 'pcBill',
                        name: 'pcBill',
                        components: {
                            default: () => import('@/views/PC/pcBill/pcBill'),
                        },
                    },
                    {
                        path: 'PCLogistics',
                        name: 'PCLogistics',
                        components: {
                            default: () => import('@/views/PC/PCLogistics/PCLogistics'),
                        },
                    },
                    {
                        path: 'PCReturn',
                        name: 'PCReturn',
                        components: {
                            default: () => import('@/views/PC/PCReturn/PCReturn'),
                        },
                    },
                    {
                        path: 'myBuy',
                        name: 'myBuy',
                        redirect: {
                            name: "myPCOrder"
                        },
                        components: {
                            default: () => import('@/views/PC/myBuy/myBuy'),
                        },

                        children: [
                            {
                                path: 'myPCOrder',
                                name: 'myPCOrder',
                                components: {
                                    default: () => import('@/views/PC/myBuy/myOrder'),
                                },
                            },
                            {
                                path: 'PCCoupon',
                                name: 'PCCoupon',
                                components: {
                                    default: () => import('@/views/PC/PCCoupon/PCCoupon'),
                                },
                            },
                            {
                                path: 'PCAddress',
                                name: 'PCAddress',
                                components: {
                                    default: () => import('@/views/PC/PCAddress/PCAddress'),
                                },
                            },
                            {
                                path: 'PCSales',
                                name: 'PCSales',
                                components: {
                                    default: () => import('@/views/PC/PCSales/PCSales'),
                                },
                            },
                            {
                                path: 'PCCollect',
                                name: 'PCCollect',
                                components: {
                                    default: () => import('@/views/PC/PCCollect/PCCollect'),
                                },
                            },
                            {
                                path: 'PCmyInfo',
                                name: 'PCmyInfo',
                                components: {
                                    default: () => import('@/views/PC/PCmyInfo/PCmyInfo'),
                                },
                            },
                            {
                                path: 'PCLogOff',
                                name: 'PCLogOff',
                                components: {
                                    default: () => import('@/views/PC/PCLogOff/PCLogOff'),
                                },
                            },
                            {
                                path: 'PCToBill',
                                name: 'PCToBill',
                                components: {
                                    default: () => import('@/views/PC/pcBill/PCToBill'),
                                },
                            },
                            {
                                path: 'MyPCAppraise',
                                name: 'MyPCAppraise',
                                components: {
                                    default: () => import('@/views/PC/MyPCAppraise/MyPCAppraise'),
                                },
                            },
                        ]
                    }
                ]
            },
            {
                path: 'login',
                name: 'login',
                components: {
                    default: () => import('@/views/PC/LoGin/LoGin'),
                },
            },
            {
                path: 'CNPCUserAgreement',
                name: 'CNPCUserAgreement',
                components: {
                    default: () => import('@/views/PC/agreed/CNPCUserAgreement'),
                },
            },
            {
                path: 'ENPCUserAgreement',
                name: 'ENPCUserAgreement',
                components: {
                    default: () => import('@/views/PC/agreed/ENPCUserAgreement'),
                },
            },
            {
                path: 'CNPCAgreement',
                name: 'CNPCAgreement',
                components: {
                    default: () => import('@/views/PC/agreed/CNPCAgreement'),
                },
            },
            {
                path: 'ENPCAgreement',
                name: 'ENPCAgreement',
                components: {
                    default: () => import('@/views/PC/agreed/ENPCAgreement'),
                },
            },
        ]
    },]