import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Cookies from 'js-cookie'
import plugins from './plugins' // plugins
import Mixin from './mixin'

import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueI18n from 'vue-i18n';
import messages from '@/i18n/i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 分页
import PcPagination from "@/components/PcPagination/PcPagination";
// 复制到粘贴板
import VueClipBoard from 'vue-clipboard2'


Vue.use(VueClipBoard)
Vue.component('PcPagination', PcPagination);
Vue.use(ElementUI);
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.mixin(Mixin)
Vue.use(plugins);
Vue.use(Vant);
Vue.config.productionTip = false
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.getItem('language') ? localStorage.getItem('language') : 'zh',
  messages
})
let vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default vue;