import Vue from 'vue'
import VueRouter from 'vue-router'
import h5Route from './h5route'
import pcRoute from './pcRoute'

Vue.use(VueRouter)
const origiinalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return origiinalPush.call(this, location).catch(err => err)
}
const routes = [
  ...h5Route,
  ...pcRoute
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes,
  scrollBehavior: ()=>({y:0}) 
})

export default router
