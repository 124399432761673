import axios from 'axios'
import { Message, MessageBox, Notification } from 'element-ui'
import { getToken } from '@/utils/auth'
import Cookies from "js-cookie";
import errorCode from '@/utils/errorCode'
import store from '@/store'
import _this from '../main.js'
import router from '@/router';

// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分 
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 30000 // request timeout
})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['X-Litemall-Token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['en'] = localStorage.getItem('language') == 'zh' ? null : localStorage.getItem('language');
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof (value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.errno || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.errmsg || errorCode['default']
  if (code === 501) {
    if (!isRelogin.show) {
      isRelogin.show = true;
      MessageBox.confirm(_this.$t('login.loginOverdue'), _this.$t('utils.systemPrompt'), {
        confirmButtonText: _this.$t('login.signInAgain'),
        cancelButtonText: _this.$t('utils.cancel'),
        type: 'warning'
      }
      ).then(() => {
        isRelogin.show = false;
        store.dispatch("LogOut").then(() => { });
        router.replace({ path: '/pc/login' })
        window.localStorage.clear();
        window.sessionStorage.clear();
      }).catch(() => {
        isRelogin.show = false;
      });
    }
    return Promise.reject(_this.$t('login.invalidSession'))
  } else if (code !== 200) {
    Notification.error({
      title: msg
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
  error => {
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export function getBaseHeader() {
  return {
    'Authorization': "Bearer " + getToken(),
    'tenant-id': Cookies.get('tenantId'),
  }
}

export default service
