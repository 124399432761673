<template>
  <div id="app">
    <router-view class="allH5Page"></router-view>
    <router-view name="tabBar" />
  </div>
</template>
<script>
import { isMobile } from "@/utils/utils";
export default {
  data() {
    return {};
  },
  beforeUpdate() {
    if (isMobile() && this.$route.path.split("/")[1] == "pc") {
      this.$router.push({
        name: "home",
      });
    } else if (!isMobile() && this.$route.path.split("/")[1] != "pc") {
      this.$router.push({
        name: "pc",
      });
    }
  },
  created() {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        let country = res.country;
        localStorage.setItem("country_calling_code",res.country_calling_code)
        if (!localStorage.getItem("language")) {
          if (country == "CN") {
            localStorage.setItem("language", "zh");
            // location.reload();
          }
        }
      });
    // if (window.navigator.geolocation) {
    //   window.navigator.geolocation.getCurrentPosition(
    //     function (position) {
    //       console.log(position);
    //     },
    //     function (err) {
    //       console.log(888, err);
    //     }
    //   );
    // } else {
    //   alert("浏览器不支持地理定位。");
    // }
  },
};
</script>
<style lang="scss">
html {
  background-color: #f4f4f4;
  height: 100%;
}
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #000;
}
li {
  list-style: none;
}
// .allH5Page {
//   padding-bottom: 50px;
// }
</style>
