var w = document.documentElement.clientWidth / 80;
document.documentElement.style.fontSize = w + 'px';
export default {
  data() {
    return {};
  },
  computed: {
    setFormItemLength() {
      return (max, trigger = "blur") => {
        return {
          max: max,
          message: "请控制在" + max + "个字以内",
          trigger,
        };
      };
    },
    setRequired: function () {
      return function (message, trigger = "bulr") {
        return {
          required: true,
          message,
          trigger,
        };
      };
    },
  },
  mounted() { },
  methods: { },
};
