
import request from '@/utils/requestPc'

// 获取验证码
export function regCaptcha(data) {
  return request({
    url: '/auth/regCaptcha',
    method: 'POST',
    data
  })
}

// 登录
export function mobileLogin(data) {
  return request({
    url: '/auth/mobileLogin',
    method: 'POST',
    data
  })
}

// 微信获取微信用户信息
export function wxPcLogin(data) {
  return request({
    url: '/auth/wxPcLogin',
    method: 'POST',
    data
  })
}

