import {
  getToken,
  removeToken,
  setToken
} from '@/utils/auth'
import {
  // 手机号密码登录
  mobileLogin,
  // 微信获取微信用户信息
  wxPcLogin,
} from "@/api/pc/login";
import Cookies from 'js-cookie'




const user = {
  state: {
    token: getToken(),
    userInfo: Cookies.get("USER_INFO_DATAs") ? JSON.parse(Cookies.get("USER_INFO_DATAs")) : null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
      Cookies.set("USER_INFO_DATAs", JSON.stringify(userInfo))
    },
  },

  actions: {
    // 登录
    Login({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        mobileLogin(userInfo).then(res => {
          res = res.data;
          setToken(res.token)
          commit('SET_USER_INFO', res.userInfo)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 微信扫描登录
    wxLogin({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {
        wxPcLogin(userInfo).then(res => {
          res = res.data;
          setToken(res.token)
          commit('SET_USER_INFO', res.userInfo)
          commit('SET_TOKEN', res.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 前端 退出
    LogOut({
      commit
    }) {
      return new Promise(resolve => {
        commit('SET_USER_INFO', null)
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
