import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
Vue.use(Vuex)

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    index: JSON.parse(sessionStorage.getItem("setIndex")) || null,
    token: JSON.parse(localStorage.getItem("token")) || null
  },
  mutations: {
    setIndex(state, index) {
      state.index = index
      sessionStorage.setItem("setIndex", JSON.stringify(index))
    },
    token(state, token) {
      state.token = token
      localStorage.setItem("token", JSON.stringify(token))
    }
  },
  modules: {
    user,
  },
  getters
})

export default store

