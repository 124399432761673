const TabBar = () => import('@/components/TabBar/TabBar');
export default [
    {
        path: '/',
        name: 'home',
        components: {
            default: () => import('@/views/H5/HomePage/HomePage'),
            tabBar: TabBar
        },
    },
    {
        path: '/shopDetail',
        name: 'shopDetail',
        component: () => import('@/views/H5/ShopDetail/ShopDetail')
    },
    {
        path: '/cart',
        name: 'cart',
        components: {
            default: () => import('@/views/H5/ShopCart/ShopCart'),
            tabBar: TabBar
        },
    },
    {
        path: '/mine',
        name: 'mine',
        components: {
            default: () => import('@/views/H5/MinePage/MinePage'),
            tabBar: TabBar
        },
    },
    {
        path: '/myOrder',
        name: 'myOrder',
        component: () => import('@/views/H5/MyOrder/MyOrder'),
    },
    {
        path: '/afterSales',
        name: 'afterSales',
        component: () => import('@/views/H5/AfterSales/AfterSales'),
    },
    {
        path: '/myCoupon',
        name: 'myCoupon',
        component: () => import('@/views/H5/MyCoupon/MyCoupon'),
    },
    {
        path: '/myAdress',
        name: 'myAdress',
        component: () => import('@/views/H5/MyAdress/MyAdress'),
    },
    {
        path: '/myCollection',
        name: 'myCollection',
        component: () => import('@/views/H5/MyCollection/MyCollection'),
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/H5/OrderDetail/OrderDetail')
    },
    {
        path: '/afterSale',
        name: 'afterSale',
        component: () => import('@/views/H5/AfterSale/AfterSale'),
    },
    {
        path: '/afterSaleDetail',
        name: 'aftersaleDetail',
        component: () => import('@/views/H5/AfterSaleDetail/AfterSaleDetail'),
    },
    {
        path: '/evaluAte',
        name: 'evaluAte',
        component: () => import('@/views/H5/EvaluAte/EvaluAte'),
    },
    {
        path: '/couponCenter',
        name: 'couponCenter',
        component: () => import('@/views/H5/CouponCenter/CouponCenter'),
    },
    {
        path: '/addAdress',
        name: 'addAdress',
        component: () => import('@/views/H5/AddAdress/AddAdress'),
    },
    {
        path: '/comment',
        name: 'comment',
        component: () => import('@/views/H5/ComMent/ComMent'),
    },
    {
        path: '/verifyLogin',
        name: 'verifyLogin',
        component: () => import('@/views/H5/VerifyLogin/VerifyLogin'),
    },
    {
        path: '/orderPage',
        name: 'orderPage',
        component: () => import('@/views/H5/OrderPage/OrderPage'),
    },
    {
        path: '/couponSelect',
        name: 'couponSelect',
        component: () => import('@/views/H5/CouponSelect/CouponSelect'),
    },
    {
        path: '/toPay',
        name: 'toPay',
        component: () => import('@/views/H5/PayPage/PayPage'),
    },
    {
        path: '/setUp',
        name: 'setUp',
        component: () => import('@/views/H5/SetUp/SetUp'),
    },
    {
        path: '/langCheck',
        name: 'langCheck',
        component: () => import('@/views/H5/MoreLang/MoreLang'),
    },
    {
        path: '/applyForInvoicing',
        name: 'applyForInvoicing',
        component: () => import('@/views/H5/ApplyForInvoicing/ApplyForInvoicing'),
    },
    {
        path: '/addHeader',
        name: 'addHeader',
        component: () => import('@/views/H5/AddHeader/AddHeader'),
    },
    {
        path: '/invoiceDetails',
        name: 'invoiceDetails',
        component: () => import('@/views/H5/InvoiceDetails/InvoiceDetails'),
    },
    {
        path: '/applicationHistory',
        name: 'applicationHistory',
        component: () => import('@/views/H5/ApplicationHistory/ApplicationHistory'),
    },
    {
        path: '/invoicePreview',
        name: 'invoicePreview',
        component: () => import('@/views/H5/InvoicePreview/InvoicePreview'),
    },
    {
        path: '/privacyAgreement',
        name: 'privacyAgreement',
        component: () => import('@/views/H5/PrivacyAgreement/PrivacyAgreement'),
    },
    {
        path: '/applicationHistoryDetails',
        name: 'applicationHistoryDetails',
        component: () => import('@/views/H5/ApplicationHistoryDetails/ApplicationHistoryDetails'),
    },
    {
        path: '/CNUserAgreement',
        name: 'CNUserAgreement',
        components: {
            default: () => import('@/views/H5/agreed/CNPCUserAgreement'),
        },
    },
    {
        path: '/ENUserAgreement',
        name: 'ENUserAgreement',
        components: {
            default: () => import('@/views/H5/agreed/ENPCUserAgreement'),
        },
    },
    {
        path: '/CNAgreement',
        name: 'CNAgreement',
        components: {
            default: () => import('@/views/H5/agreed/CNPCAgreement'),
        },
    },
    {
        path: '/ENAgreement',
        name: 'ENAgreement',
        components: {
            default: () => import('@/views/H5/agreed/ENPCAgreement'),
        },
    },
]