// 全局中文配置
const zh = {
    // 共用
    utils: {
        customerServiceHotline: '客服热线',
        paymentSuccessful: '支付成功',
        available: '使用',
        full: '满',
        evaluated: '查看评价',
        rescinded: '已撤销',
        invoicingFailed: '开票失败',
        successfullyInvoiced: '开票成功',
        comprehensiveEvaluation: '综合评价',
        pictureDrying: '晒图片',
        commodity: '商品',
        evaluationContent: '评价内容',
        resetting: '重置',
        shippingAddress: '收货地址',
        status: '状态',
        discountCoupon: '优惠劵',
        Alipay: '支付宝',
        WeChat: '微信',
        unit: "￥",
        personalCenter: '个人中心',
        shoppingCart: '购物车',
        selectAll: '全选',
        specification: '规格',
        quantity: '数量',
        unitPrice: '单价',
        delete: '删除',
        selectedGoods: '已选商品',
        piece: '件',
        amountTo: '合计',
        settlement: '结算',
        yuan: '元',
        coupon: '优惠券',
        buyNow: '立即购买',
        treasureEvaluation: '宝贝评价',
        babyDetails: '宝贝详情',
        collect: '收藏',
        all: '全部',
        reply: '回复',
        browse: '浏览',
        order: '次',
        pleaseUse: '请使用',
        pleaseEnter: '请输入',
        pleaseTick: '请勾选',
        pleaseSelect: '请选择',
        select: '选择',
        systemPrompt: "系统提示",
        cancel: '取消',
        notOrder: '您还没有相关的订单',
        affirm: "确认",
        chineseMainland: '中国大陆',
        continentalUnitedStates: '美国大陆',
        effective: "有效的",
        urlType: '上传图片只能是 jpg/png/jpeg 格式!',
        urlSize: '上传图片大小不能超过 5MB!',
        noTemporarily: '暂无凭证',
        noAvailable: '暂无数据',
        copySucceeded: '复制成功',
        received: '已揽件',
        inTransit: '运输中',
        sign: '已签收',
        problemPiece: '已揽件',
        couponCollection: '优惠券领取',
        unclaimed: '待领取',
        riskAcknowledgements: '风险须知'


    },
    address: {
        location: '所在地区',
        postalCode: '邮政编码',
        defaultAddress: '默认地址',
        setDdefault: '设为默认',
        selectAddress: '选择地址'
    },
    // 登录页
    login: {
        pleaseLogin: '请登录',
        UserAgreement: "用户协议",
        PrivacyPolicy: "隐私政策",
        ZHlanguage: "中文",
        ENlanguage: "英语",
        AccountLogin: "账号登录",
        SignInUseWx: "使用微信扫一扫登录",
        login: "登 录",
        loggingIn: "登 录 中",
        enterPhone: "请输入您的手机号",
        SMS: "短信验证码",
        getSMS: "获取验证码",
        Reacquire: "重新获取",
        readAndAgree: "已阅读并同意华康酒",
        and: "和",
        codeError: "账号或短信验证码错误，请重新输入",
        ScanSucceeded: "扫描成功",
        AllowInWx: "在微信中轻触允许即可登录",
        acquireSeconds: '秒后获取',
        loginOverdue: '登录状态已过期，请重新登录',
        signInAgain: '重新登录',
        invalidSession: '无效的会话，或者会话已过期，请重新登录。'
    },
    // 头部导航
    pcTop: {
        myOrder: '我的订单',
        searchBaby: '搜索宝贝',
        LoginRegistration: '登录',
    },
    // 首页
    indexPage: {
        hotSales: '热销',
        rise: '起',
        learnMore: '了解更多',
    },
    // 底部导航
    pcBottom: {
        huakangLiquor: '华康酒',
        hkTitle: '华夏儿女 幸福安康',
        hkIntroduce: '华康酒，产自中国酒都--茅台镇，地处赤水河畔，享有得天独厚的天然条件，采用传统的酿造工艺，经过九次蒸煮、八次发酵、七次取酒，三年以上岁月沉淀而成。具有酱香突出、优雅细腻、酒体醇厚、回味悠长、空杯留香等特点。',
        serviceHotline: '服务热线',
        copyright: '版权所有',
        copyrightValue: '华康酒业有限公司版权所有',
        legalNotice: '法律声明',
        privacyAgreement: '隐私协议',
        filingNo: '豫ICP备2022023918号-2',
        weChat: '华康酒业微信公众号',
    },
    // 悬浮标
    sideBar: {
        mobile: '手机',
        manualCustomerService: '人工客服',
        backToTop: '回到顶部',
    },
    // 我的订单
    myOrder: {
        orderCenter: '订单中心',
        allOrders: '全部订单',
        toBePaid: '待付款',
        canceled: '已取消',
        canceledSystem: '已取消(系统)',
        toBeshipped: '待发货',
        goodsToBeReceived: '待收货',
        toBeEvaluated: '待评价',
        afterSales: '售后',
        myAddress: '我的地址',
        myComments: '我的评价',
        myCollection: '我的收藏',
        accountManagement: '账号管理',
        myInformation: '我的信息',
        logOut: '退出登录',
        accountCancellation: '注销账号',
        name: '姓名',
        orderNumber: '订单号',
        onlinePayment: '在线支付',
        orderDetails: '订单详情',
        actualPayment: '实付款',
        freightIncluded: '含运费',
        applyForAfterAalesService: '申请售后',
        applyArefund: '申请退款',
        afterSalesDetails: '售后详情',
        afterPayment: '付款后',
        delivery: '48小时内发货',
        checkLogistics: '查看物流',
        immediateEvaluation: '立即评价',
        applyBilling: '申请开票',
        deleteOrder: '删除订单',
        immediatePayment: '立即付款',
        cancelOrder: '取消订单',
        confirmReceiptGoods: '确认收货',
        urgeDelivery: '催发货',
        ScanPayment: '扫一扫支付'
    },
    // 购物车
    shopCart: {
        productInformation: '产品信息',
        amount: '金额',
        operation: '操作',
    },
    // 提交订单后，去付款
    pcPay: {
        goPay: '订单已提交！去付款喽',
        pleaseEnter: '请在',
        minutes: '分',
        seconds: '秒',
        remainingTime: '内完成支付，超时后将取消订单',
        receivingInformation: '收货信息',
        commodityInformation: '商品信息',
        totalAmountPayable: '应付总额',
        methodPayment: '支付方式'
    },
    // 详情
    pcAppraise: {
        price: '价格',
        evaluation: '评价',
        cumulative: '累计',
        suggestion: '其他买家需要您的建议哦！',

    },
    // 商品详情
    shopDetail: {
        deliverTo: '配送至',
        guarantee: '保障',
        logistics: '物流',
        addShopCart: '加入购物车',
        picturesVideos: '有图/有视频'
    },
    //menu
    menu: {
        OrderCenter: "订单中心",
        AllOrders: "全部订单",
        ToBePaid: "待付款",
        ToBeShipped: "待发货",
        ToBeReceived: "待收货",
        ToBeEvaluated: "待评价",
        AfterSales: "售后",
        PersonalCenter: "个人中心",
        MyAddress: "我的地址",
        MyComments: "我的评价",
        MyCollection: "我的收藏",
        MyInvoice: "我的发票",
        Coupon: "优惠券",
        Management: "账号管理",
        MyInformation: "我的信息",
        LogOut: "退出登录",
        Cancellation: "注销账号"
    },
    //账号管理
    Management: {
        avatar: "头像",
        Nickname: "昵称",
        Phone: "我的手机号",
        DoToLeave: "真的要离开吗？",
        ConfirmExit: "确认退出",
        Instructions: "注销账户须知",
        AfterCancellation: "注销后，账号下的全部权益将被清除。",
        ClearAllInformation: "清空华康酒平台所有信息，包括但不仅限于资料、短信信息、消费信息等。",
        OnceCancelled: "账号一旦注销完成，将无法恢复。",
        Understood: "我已清楚风险，确认继续",
        ConfirmLogoff: "确认注销",
        Save: "保存",
        ModifyAvatar: "修改头像",
        Setavatar: "设置头像",
        AvatarSettings: "头像设置",
        ClickUpload: "点击上传",
        OnlyFile: "只能上传jpg/png文件，且不超过500m",
        EnterNickname: "请输入昵称",
        EnterPhone: "请输入你的手机号码",
        WantToLogOff: "真的要注销吗？",
        AccountCancellation: "注销账号"
    },
    //售后
    AfterSale: {
        Applying: "申请中",
        UnderReview: "审核中",
        Completed: "已完成",
        Rejected: "已拒绝",
        OrderNumber: "订单号:",
        ApplicationSubmitted: "申请已提交",
        MerchantUnderReview: "商家审核中",
        RefundSucceeded: "退款成功",
        MerchantRejected: "商家已拒绝",
        specifications: "规格",
        quantity: "数量",
        unitPrice: "单价",
        ActualPayment: "实付款",
        Includingfreight: "含运费",
        RefundOnly: "仅退款",
        ApplicationAmount: "申请金额",
        ServiceDetails: "服务详情",
        MyInvoice: "我的发票",
        AfterSales: "售后",
        ViewDetails: "查看详情",
        BuyerSubmitsApplication: "买家提交申请",
        SellerUnderReview: "卖家审核中",
        RefundNumber: "退款编号",
        RefundFailed: "退款失败",
        OrderDetails: "订单详情",
        RefundType: "退款类型: ",
        RefundReason: "退款原因: ",
        UploadVoucher: "上传凭证",
        ReturnRefund: "退货退款",
        DescribeHere: "在这里描述您的退款原因！",
        UploadImagesInfo: "最多只能上传6张图片",
        SubmitApplication: "提交申请"
    },
    // 我的评价
    myAppraise: {
        nickName: "昵称",
        appraise: "评价",
        Browsed: "被浏览",
        time: "次",
        liked: "被赞",
        delete: "删除",
        ComprehensiveScore: "综合评分：",
        Star: "星",
        MyComments: "我的评价:"
    },
    //Bill
    Bill: {
        MyInvoice: "我的发票",
    },
    //quicken
    "查看详情": "查看详情",
    "买家提交申请": "买家提交申请",
    "卖家审核中": "卖家审核中",
    "申请失败": "申请失败",
    "申请成功": "申请成功",
    "发票类型": "发票类型",
    "增值税电子普通发票": "增值税电子普通发票",
    "发票内容": "发票内容",
    "发票内容将显示详细商品名称与价格信息": "发票内容将显示详细商品名称与价格信息",
    "抬头类型": "抬头类型",
    "个人或事业单位": "个人或事业单位",
    "个人": "个人",
    "事业单位": "事业单位",
    "发票抬头": "发票抬头",
    "请输入个人或公司名称": "请输入个人或公司名称",
    "税号": "税号",
    "请输入税号": "请输入税号",
    "开户银行": "开户银行",
    "请输入开户银行地址": "请输入开户银行地址",
    "银行账号": "银行账号",
    "请输入银行账号": "请输入银行账号",
    "企业地址": "企业地址",
    "请输入企业地址": "请输入企业地址",
    "企业电话": "企业电话",
    "请输入企业电话": "请输入企业电话",
    "被拒原因": "被拒原因",
    "提交": "提交",
    "修改申请": "修改申请",
    "撤销申请": "撤销申请",
    "下载": "下载",
    "请输入发票内容": "请输入发票内容",
    "请选择抬头类型": "请选择抬头类型",
    "请填写发票类型": "请填写发票类型",
    "请输入发票抬头": "请输入发票抬头",
    "提交申请": "提交申请",
    "商家审核": "商家审核",
    "喜欢的商品": "喜欢的商品",
    "删除": "删除",
    "元": "元",

    "订单号": "订单号",
    "规格：": "规格：",
    "数量：": "数量：",
    "单价：": "单价：",
    "￥": "￥",
    "订单详情": "订单详情",
    "申请金额：": "申请金额：",
    "申请售后": "申请售后",
    "付款后": "付款后",
    "48小时内发货": "48小时内发货",
    "退款/退货": "退款/退货",
    "查看物流": "查看物流",
    "仅退款": "仅退款",
    // "申请金额：":"申请金额：",
    "发票详情": "发票详情",
    "联系卖家": "联系卖家",
    "全选": "全选",
    // "删除":"删除",
    "已选商品": "已选商品",
    "件": "件",
    "合计：": "合计：",
    "结算": "结算",


    // "元":"元",
    "满": "满",
    "使用": "使用",
    "有效期：": "有效期：",
    "待使用": "待使用",
    "未使用": "未使用",
    "已使用": "已使用",
    "已过期": "已过期",

    "我的订单": "我的订单",
    "待收货": "待收货",
    // "查看物流":"查看物流",
    "复制": "复制",
    "联系电话": "联系电话",


    "申请历史": "申请历史",
    "申请已提交": "申请已提交",
    "商家审核中": "商家审核中",

    "立即领取": "立即领取",
    "立即使用": "立即使用",
    // "已使用":"已使用",
    // "已过期":"已过期",

    "取消": "取消",
    "保存": "保存",
    "我的地址": "我的地址",
    "姓名": "姓名",
    "地址信息": "地址信息",
    "详细地址": "详细地址",
    "邮编": "邮编",
    "手机号": "手机号",
    "操作": "操作",
    "修改": "修改",
    // "删除":"删除",
    "设为默认": "设为默认",
    "默认地址": "默认地址",
    "请选择区号": "请选择区号",
    "请选择": "请选择",
    // "设为默认":"设为默认",

    'language': '语言',
    'myorder': '我的订单',
    'VoucherShopping': '领券购物',
    "HuakangWine": "华康酒",
    "ClickToClaim": '点击领取',
    "SurprisePrice": "惊喜价",
    "SurpriseTtem": 'http://122.114.166.129:6257/zy4qwz94ix6peybhc898.png',
    "BuyNow": 'http://122.114.166.129:6257/ftzl70rmy18dqfxgkf5c.png',
    "tagImg": 'http://122.114.166.129:6257/cabw49vu2hlbxmsfvagf.png',
    "ReceivedSuccessfully": "领取成功",
    "returnOfGoods": "30天无忧退货",
    "drawback": "48小时快速退款",
    "franking": "满88元免邮费",
    'empty': '空空如也~',
    "toAdd": '去添加点什么吧',
    "goShop": '去逛逛',
    "edit": '编辑',
    "accomplish": '完成',
    "placeAnOrder": '下单',
    "Loading": '加载中...',
    "SetUp": '设置',
    "ToBePaid": "待付款",
    "ToBeShipped": "待发货",
    "GoodsToBeReceived": "待收货",
    "ToBeEvaluated": "待评价",
    "Completed": "已完成",
    "AfterSales": "售后",
    "MyService": "我的服务",
    "coupon": "优惠券",
    "MyCollection": "我的收藏",
    "MyAddress": "我的地址",
    "ContactCustomerService": "联系客服",
    "Multilingual": "多语言",
    "UserServiceAgreement": "用户服务协议",
    "PrivacyAgreement": "隐私协议",
    "AboutUs": "关于我们",
    "AccountCancellation": "注销账号",
    "TickAgreement": "请勾选用户协议",
    "LogOut": "退出登录",
    "LogOutSure": "确定要退出登录？",
    "AccountCancellationSure": "确定要注销账号？",
    "save": "保存",
    "cancel": "取消",
    "PleaseSelect": '请选择',
    "affirm": "确认",
    "SelectState": "选择省 / 市 / 区",
    "SelectState1": "省份、城市、区县",
    "place1": '收货人姓名',
    "place2": '收货人手机号',
    "place3": '街道门牌、楼层房间号等信息',
    "place31": '详细地址, 如街道、楼盘号等',
    "tip0": '姓名',
    "tip1": '电话',
    "tip11": '手机号',
    "tip2": '地区',
    "tip3": '详细地址',
    "tip4": '设为默认收货地址',
    "SavedSuccessfully": "保存成功",
    "RefundGoods": "退款商品",
    "orderDetails": "订单明细",
    "TotalPriceOfGoods": "商品总价",
    "element": "元",
    "freight": "运费",
    "Discount": "优惠",
    "ActualPaymentOfOrder": "订单实付",
    "operation": "操作",
    "RefundType": "退款类型",
    "RefundReason": "退款原因",
    "pleaseEnter": "请输入",
    "refundAmount": "退款金额",
    "payment-freight": "不可修改，即订单实付- 运费",
    "RefundInstructions": "退款说明",
    "UploadVoucher": "上传凭证",
    "UploadUpToThree": "最多上传三张",
    "after-salesService": "申请售后",
    "notReceived": "未收货退款",
    "RefundWithoutReturn": "不退货退款",
    "returnRefund": "退货退款",
    "refundType": "请选择退款类型",
    "refundReason": "请输入退款原因",
    "Submitting": "提交中...",
    "ApplyAfter-salesSuccess": "申请售后成功",
    "RefundDetails": "退款明细",
    "After-salesStatus": "售后状态",
    "ApplicationTime": "申请时间",
    "ServiceNo": "服务编号",
    "paymentOfOrder-freight": "即订单实付- 运费",
    "NotApplied": "未申请",
    "AppliedToBeReviewed": "已申请，待审核",
    "ApprovedToBeRefunded": "审核通过，待退款",
    "RefundSucceeded": "退款成功",
    "FailedToPassTheReviewRejected": "审核不通过，已拒绝",
    "InApplication": "申请中",
    "Processing": "处理中",
    "Rejected": "已拒绝",
    "NoMore": "没有更多了",
    "After-saleNo": "售后编号",
    "Items": "件商品",
    "RefundAmountApplied": "申请退款金额",
    "WithdrawalOfApplication": "撤回申请",
    "comitSure": "确定要撤回该申请？",
    "whole": "全部",
    "HavePicture": "有图",
    "MerchantReply": "商家回复",
    "FromReceipt": "领券当日起",
    "inDays": "天内可用",
    "full": "满",
    "MetaUse": "元使用",
    "GetItNow": "立即领取",
    'sure': '确定',
    "UseNow": "立即使用",
    "score": "评分",
    "ImageUpload": "图片上传",
    "publish": "发表",
    "VisibleToAll": "留言经过筛选后，对所有人可见",
    "perfectContentment": "十分满意",
    "uploading": "上传中...",
    "UploadSucceeded": "上传成功",
    "VeryPoor": "很差",
    "NotVerySatisfied": "不太满意",
    "satisfied": "满意",
    "QuiteSatisfied": "比较满意",
    "ComSucceeded": "评论成功",
    'LoginRegistration': "登录/注册",
    "default": "默认",
    "noAddress": "收货地址还没有~~~",
    "newlyBuild": "新建",
    "deleteAddressSure": "确定要删除地址？",
    "FromYuan": "元起",
    "noCollection": "还没有收藏",
    "sureToDelete": "确定删除吗？",
    "deleteSuccess": "删除成功",
    "ItsOver": "已经到底了",
    "notUsed": "未使用",
    "Used": "已使用",
    "Expired": "已过期",
    "VoucherCollectionCenter": "领券中心",
    "OrderNo": "订单编号",
    "common": "共",
    "PaidIn": "实付",
    "OrderTime": "下单时间",
    "OrderMessage": "订单留言",
    "cancellationOfOrder": "取消订单",
    "ToPay": "去付款",
    "ToPay2": "去支付",
    "ConfirmEeceipt": "确认收货",
    "DeleteOrder": "删除订单",
    "ApplyForRefund": "申请退款",
    "ProductInformation": "商品信息",
    "ToEvaluate": "去评价",
    "BuyAgain": "再次购买",
    "TotalMerchandise": "商品合计",
    "OrderFreight": "订单运费",
    "OrderDiscount": "订单优惠",
    "CourierServicesCompany": "快递公司",
    "LogisticsBillNo": "物流单号",
    "today": "今天",
    "yesterday": "昨天",
    "cancelOrder": "确定要取消此订单？",
    "cancelSuccess": "取消订单成功",
    "ConfirmReceipt": "确认收货？",
    "ConfirmSuccess": "确认收货成功",
    "deleteOrder": "确定要删除此订单？",
    "deletedSuccess": "删除订单成功",
    "refundSure": "确定要申请退款？",
    "refund": "退款",
    "RefundApplicationSucceeded": "申请退款成功",
    "noAddressToAdd": "还没有收货地址，去添加",
    "noCollections": "没有可用的优惠券",
    "fix": "张",
    "ToMessage": "如需要，请输入留言",
    "checkAdress": '请选择收货地址',
    "success": "成功",
    "Selected": "已选择",
    "SelectSpecifications": "选择规格",
    "appraise": "评价",
    "ViewAll": "查看全部",
    "ProductIntroduction": "产品介绍",
    "commonProblem": "常见问题",
    "price": "价格",
    "GroupPurchaseVerticalReduction": "团购立减",
    "num": "数量",
    "addShopCart": "加入购物车",
    "ItemSoldOut": "商品已售空",
    "JoinGroup": "参加团购",
    "BuyNow2": "立即购买",
    "selSpec": "规格数量选择",
    "plaseSelSpec": "请选择规格数量",
    "selFullspecification": "请选择完整规格",
    "NoInventory": "没有库存",
    "addSucess": "添加成功",
    "noFinedGoods": "规格所对应货品不存在",
    "atypism": "商品数量价格和货品不一致",
    "EnterPhoneNumber": "输入手机号",
    "EnterPhoneNumberPlease": "请输入手机号",
    "EnterVerificationCode": "输入验证码",
    "EnterVerificationCodePlease": "请输入验证码",
    "GetVerificationCode": "获取验证码",
    "SignIn": "登录",
    "EnterName": "请输入姓名",
    "EnterTel": "请输入手机号码",
    "EnterRelTel": "请输入正确的手机号",
    "EnterCountry": "请输入省市区",
    "EnterAdress": "请输入详细地址",
    "InvoicedAmount": "开票金额",
    "InvoiceType": "发票类型",
    "HeadingType": "抬头类型",
    "VAT": "增值税电子普通发票",
    "EnterpriseUnit": "企业单位",
    "IndividualsOrInstitutions": "个人或事业单位",
    "FillIn": "填写需要开具发票的企业名称",
    "dutyParagraph": "税号",
    "FillIn2": "填写纳税人识别号",
    "BankOfDeposit": "开户银行",
    "Optional": "选填",
    "bankAccount": "银行账号",
    "BusinessAddress": "企业地址",
    "EnterpriseTelephone": "企业电话",
    "detailed": "明细",
    "SelectHeader": "选择抬头",
    "noInvoice": "您还没有发票抬头",
    "AddANew": "添加新的抬头",
    "InvoiceContent": "发票内容",
    "willSisplay": "发票内容将显示详细商品名称与价格信息",
    "invoice": "发票抬头",
    "invoiceName": "抬头名称",
    "SetAsDefault": "设为默认",
    "consignee": "收货人",
    "InvoiceDetails": "发票详情",
    "SuccessfulApplication": "申请成功",
    "Rejected2": "已被拒",
    "invoicePrice": "发票金额",
    "ApplicationDate": "申请日期",
    "ModifyApplication": "修改申请",
    "CancellationOfApplication": "撤销申请",
    "ViewReasonsForRejection": "查看被拒理由",
    "ViewInvoice": "查看发票",
    "ApplicationHistory": "申请历史",
    "SaveToPhone": "保存到手机",
    "ApplyForInvoicing": "申请开票",
    "homePage": "首页",
    "ShoppingCart": "购物车",
    "personal": "个人",
    "classify": "分类",
    "ConfirmPayment": '确认支付',
    "WeChatPayment": '微信支付',
    "AlipayPayment": '支付宝支付',
    "FollowSystem": '跟随系统',
    "SubmitApplication": '提交申请',
}
export default zh