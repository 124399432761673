// 全局英文配置
const en = {
    // 共用
    utils: {
        customerServiceHotline: 'Customer service hotline',
        paymentSuccessful: 'Payment successful',
        available: 'make use of',
        full: 'full',
        evaluated: 'View comments',
        rescinded: 'rescinded',
        invoicingFailed: 'Invoicing failed',
        successfullyInvoiced: 'Successfully invoiced',
        comprehensiveEvaluation: 'Comprehensive evaluation',
        pictureDrying: 'Picture drying',
        commodity: 'commodity',
        evaluationContent: 'Evaluation content',
        resetting: 'resetting',
        shippingAddress: 'Shipping address',
        status: 'status',
        discountCoupon: 'Discount coupon',
        Alipay: 'Alipay',
        WeChat: 'WeChat',
        unit: "$",
        personalCenter: 'Center',
        shoppingCart: 'ShopCart',
        selectAll: 'selectAll',
        specification: 'specification',
        quantity: 'quantity',
        unitPrice: 'Unit price',
        delete: 'delete',
        selectedGoods: 'Selected goods',
        piece: 'piece',
        amountTo: 'amount to',
        settlement: 'settlement',
        yuan: 'yuan',
        coupon: 'coupon',
        buyNow: 'Buy Now',
        treasureEvaluation: 'Treasure evaluation',
        babyDetails: 'Baby details',
        collect: 'collect',
        all: 'all',
        reply: 'reply',
        browse: 'browse',
        order: 'order',
        pleaseUse: 'Please use',
        pleaseEnter: 'Please enter',
        pleaseTick: 'Please tick',
        pleaseSelect: 'Please select',
        select: 'select',
        systemPrompt: "System prompt",
        cancel: 'cancel',
        notOrder: 'You have no related orders yet',
        affirm: "affirm",
        chineseMainland: 'Chinese Mainland',
        continentalUnitedStates: 'Continental United States',
        effective: "effective",
        urlType: 'Uploaded images can only be in jpg/png/jpeg format!',
        urlSize: 'The size of the uploaded image cannot exceed 5MB!',
        noTemporarily: 'No voucher temporarily',
        noAvailable: 'No data available',
        copySucceeded: 'Copy succeeded',
        received: 'Received',
        inTransit: 'In transit',
        sign: 'Signed for',
        problemPiece: 'Purchased items',
        couponCollection: 'Coupon collection',
        unclaimed: 'unclaimed',
        riskAcknowledgements: 'risk acknowledgements'
    },
    address: {
        location: 'Location',
        postalCode: 'Postal Code',
        defaultAddress: 'Default Address',
        setDdefault: 'Set as default',
        selectAddress: 'Select Address'


    },
    // 登录
    login: {
        pleaseLogin: 'Please log in',
        UserAgreement: "UserAgreement",
        PrivacyPolicy: "PrivacyPolicy",
        ZHlanguage: "chinese",
        ENlanguage: "english",
        AccountLogin: "AccountLogin",
        SignInUseWx: "Sign in using WeChat scanning",
        login: "Login",
        loggingIn: "Logging in",
        enterPhone: "Please enter your phone number",
        SMS: "SMS verification code",
        getSMS: "ObtainCode",
        Reacquire: "Reacquire",
        readAndAgree: "read and agree to Huakang Liquor",
        and: " and",
        codeError: "Account or SMS verification code error, please re-enter",
        ScanSucceeded: "Scan succeeded",
        AllowInWx: "Tap Allow in WeChat to log in",
        acquireSeconds: 'Acquire in seconds',
        loginOverdue: 'Login status has expired, please log in again',
        signInAgain: 'Sign in again',
        invalidSession: 'Invalid session, or the session has expired, please log in again.',
    },
    // 头部导航
    pcTop: {
        myOrder: 'Order',
        searchBaby: 'Search Baby',
        LoginRegistration: "Login",
    },
    // 首页
    indexPage: {
        hotSales: 'Hot sales',
        rise: 'rise',
        learnMore: 'Learn more',
    },
    // 底部导航
    pcBottom: {
        huakangLiquor: 'Huakang liquor',
        hkTitle: 'Happy and Healthy Chinese Children',
        hkIntroduce: 'Huakang liquor is produced in Maotai Town, the wine capital of China, and is located on the bank of the Chishui River. It enjoys unique natural conditions. It adopts traditional brewing techniques, undergoes nine times of steaming, eight times of fermentation, seven times of wine extraction, and is precipitated over three years. It has prominent soy sauce aroma, elegant and delicate, full-bodied wine, a long aftertaste, and a lingering aroma in an empty glass.',
        serviceHotline: 'Service hotline',
        copyright: 'Copyright',
        copyrightValue: 'Huakang Liquor Co., Ltd. All rights reserved',
        legalNotice: 'Legal Notice',
        privacyAgreement: 'Privacy Agreement',
        filingNo: 'YUICPB No. 2022023918-2',
        weChat: 'WeChat official account of Huakang Wine'
    },
    // 悬浮标
    sideBar: {
        mobile: 'Mobile',
        manualCustomerService: 'service',
        backToTop: 'Back to top'
    },
    // 我的订单
    myOrder: {
        orderCenter: 'Order Center',
        allOrders: 'All orders',
        toBePaid: 'To be paid',
        canceled: 'Canceled',
        canceledSystem: 'canceled(system)',
        toBeshipped: 'To be shipped',
        goodsToBeReceived: 'Goods to be received',
        toBeEvaluated: 'To be evaluated',
        afterSales: 'After Sales',
        myAddress: 'My address',
        myComments: 'My Comments',
        myCollection: ' My Collection',
        accountManagement: 'account management',
        myInformation: 'My Information',
        logOut: 'Log out',
        accountCancellation: 'Account cancellation',
        name: 'name',
        orderNumber: 'Order number',
        onlinePayment: 'Online payment',
        orderDetails: 'Order details',
        actualPayment: 'Actual payment',
        freightIncluded: 'Freight included',
        applyForAfterAalesService: 'Apply for after-sales service',
        applyArefund: 'Apply for a refund',
        afterSalesDetails: 'After sales details',
        afterPayment: 'After payment',
        delivery: 'Delivery within 48 hours',
        checkLogistics: 'Check logistics',
        immediateEvaluation: 'Immediate evaluation',
        applyBilling: 'Apply for billing',
        deleteOrder: 'Delete an order',
        immediatePayment: 'Immediate payment',
        cancelOrder: 'Cancel an order',
        confirmReceiptGoods: 'Confirm receipt of goods',
        urgeDelivery: 'Urge delivery',
        ScanPayment: 'Scan Payment'
    },
    // 购物车
    shopCart: {
        productInformation: 'Product information',
        amount: 'amount',
        operation: 'operation',
    },
    // 提交订单后，去付款
    pcPay: {
        goPay: 'Order submitted! Go pay',
        pleaseEnter: 'Please enter',
        minutes: 'minutes',
        seconds: 'seconds',
        remainingTime: 'Payment completed within, order will be cancelled after timeout',
        receivingInformation: 'Receiving information',
        commodityInformation: 'Commodity information',
        totalAmountPayable: 'Total amount payable',
        methodPayment: 'Method of payment'
    },
    // 详情
    pcAppraise: {
        price: 'price',
        evaluation: 'evaluation',
        cumulative: 'Cumulative',
        suggestion: 'Other buyers need your advice!',

    },
    // 商品详情
    shopDetail: {
        deliverTo: 'Deliver',
        guarantee: 'ensure',
        logistics: 'logistics',
        addShopCart: 'AddShopCart',
        picturesVideos: 'There are pictures/videos'
    },
    //menu
    menu: {
        OrderCenter: "OrderCenter",
        AllOrders: "AllOrders",
        ToBePaid: "ToBePaid",
        ToBeShipped: "ToBeShipped",
        ToBeReceived: "ToBeReceived",
        ToBeEvaluated: "ToBeEvaluated",
        AfterSales: "AfterSales",
        PersonalCenter: "PersonalCenter",
        MyAddress: "MyAddress",
        MyComments: "MyComments",
        MyCollection: "MyCollection",
        MyInvoice: "MyInvoice",
        Coupon: "Coupon",
        Management: "Management",
        MyInformation: "MyInformation",
        LogOut: "LogOut",
        Cancellation: "Cancellation"
    },
    //账号管理
    Management: {
        avatar: "Avatar",
        Nickname: "Nickname",
        Phone: "Phone",
        DoToLeave: "Do you really want to leave?",
        ConfirmExit: "ConfirmExit",
        Instructions: "Instructions for Account Cancellation",
        AfterCancellation: "After cancellation, all rights and interests under the account will be cleared.",
        ClearAllInformation: "Clear all information on the Huakang liquor platform, including but not limited to data, SMS information, consumption information, etc.",
        OnceCancelled: "Once the account is cancelled, it cannot be restored.",
        Understood: "I have understood the risks and confirm to continue",
        ConfirmLogoff: "Confirm Logoff",
        Save: "Save",
        ModifyAvatar: "Modify avatar",
        Setavatar: "Setavatar",
        AvatarSettings: 'Avatar settings',
        ClickUpload: "ClickUpload",
        OnlyFile: "Only jpg/png files can be uploaded, and no more than 500m",
        EnterNickname: "Please enter a nickname",
        EnterPhone: "Please enter your phone number",
        WantToLogOff: "Are you sure you want to log off?",
        AccountCancellation: "AccountCancellation"
    },
    //售后
    AfterSale: {
        Applying: "Applying",
        UnderReview: "UnderReview",
        Completed: "Completed",
        Rejected: "Rejected",
        OrderNumber: "OrderNumber:",
        ApplicationSubmitted: "ApplicationSubmitted",
        MerchantUnderReview: "MerchantUnderReview",
        RefundSucceeded: "RefundSucceeded",
        MerchantRejected: "MerchantRejected",
        specifications: "specifications",
        quantity: "quantity",
        unitPrice: "unitPrice",
        ActualPayment: "ActualPayment",
        Includingfreight: "Includingfreight",
        RefundOnly: "RefundOnly",
        ApplicationAmount: "Amount",
        ServiceDetails: "ServiceDetails",
        MyInvoice: "MyInvoice",
        AfterSales: "AfterSales",
        ViewDetails: "View Details",
        BuyerSubmitsApplication: "Buyer submits application",
        SellerUnderReview: "Seller under review",
        RefundNumber: "RefundNumber",
        RefundFailed: "Refund failed",
        OrderDetails: "Order Details",
        RefundType: "RefundType: ",
        RefundReason: "RefundReason: ",
        UploadVoucher: "UploadVoucher: ",
        ReturnRefund: "Return Refund",
        DescribeHere: "Describe your refund reason here!",
        UploadImagesInfo: "You can only upload up to 6 images",
        SubmitApplication: "Submit application"

    },
    //appraise
    myAppraise: {
        nickName: "nickName",
        appraise: "appraise",
        Browsed: "Browsed",
        time: "time",
        liked: "liked",
        delete: "delete",
        ComprehensiveScore: "Comprehensive score:",
        Star: "Star",
        MyComments: "My comments:"
    },
    //Bill
    Bill: {
        MyInvoice: "My Invoice",
    },

    //quicken
    "查看详情": "View Details",
    "买家提交申请": "Buyer submits application",
    "卖家审核中": "Seller under review",
    "申请失败": "Application failed",
    "申请成功": "Successful application",
    "发票类型": "InvoiceType",
    "增值税电子普通发票": "VAT electronic ordinary invoice",
    "发票内容": "Invoice content",
    "发票内容将显示详细商品名称与价格信息": "The invoice content will display detailed product name and price information",
    "抬头类型": "Heading type",
    "个人或事业单位": "Individuals or institutions",
    "个人": "individual",
    "事业单位": "institution",
    "发票抬头": "Invoice Header",
    "请输入个人或公司名称": "Please enter an individual or company name",
    "税号": "dutyParagraph",
    "请输入税号": "Please enter a tax number",
    "开户银行": "DepositBank",
    "请输入开户银行地址": "Please enter the address of the deposit bank",
    "银行账号": "bankAccount",
    "请输入银行账号": "Please enter a bank account number",
    "企业地址": "address",
    "请输入企业地址": "Please enter the business address",
    "企业电话": "CorporatePhone",
    "请输入企业电话": "Please enter the company phone number",
    "被拒原因": "Reason for rejection",
    "提交": "submit",
    "修改申请": "Modify",
    "撤销申请": "Cancellation",
    "下载": "download",
    "请输入发票内容": "Please enter invoice content",
    "请选择抬头类型": "Please select a header type",
    "请填写发票类型": "Please fill in the invoice type",
    "请输入发票抬头": "Please enter invoice title",
    "提交申请": "Submit application",
    "商家审核": "Merchant review",
    "喜欢的商品": "Favorite products",
    "删除": "delete",
    "元": "yuan",

    "订单号": "order number",
    "规格：": "Specifications:",
    "数量：": "number:",
    "单价：": "unit-price:",
    "￥": "$",
    "订单详情": "Order Details",
    "申请金额：": "Application amount:",
    "申请售后": "after-sales",
    "付款后": "After payment",
    "48小时内发货": "Delivery within 48 hours",
    "退款/退货": "Refund/Return",
    "查看物流": "View Logistics",
    "仅退款": "Refund only",
    // "申请金额：":"申请金额：",
    "发票详情": "Invoice Details",
    "联系卖家": "Contact the seller",
    "全选": "Select All",
    // "删除":"删除",
    "已选商品": "Selected item",
    "件": "piece",
    "合计：": "total:",
    "结算": "settle accounts",

    // "元":"元",
    "满": "full",
    "使用": "use",
    "有效期：": "validity:",
    "待使用": "To be used",
    "未使用": "not used",
    "已使用": "Used",
    "已过期": "Expired",

    "我的订单": "My Order",
    "待收货": "Goods to be received",
    // "查看物流":"查看物流",
    "复制": "duplicate",
    "联系电话": "contact number",

    "申请历史": "Application History",
    "申请已提交": "Application submitted",
    "商家审核中": "Merchant under review",

    "立即领取": "Get it now",
    "立即使用": "Use Now",

    "取消": "cancel",
    "保存": "preserve",
    "我的地址": "My address",
    "姓名": "name",
    "地址信息": "Address",
    "详细地址": "Detailed",
    "邮编": "Zip code",
    "手机号": "phone",
    "操作": "operate",
    "修改": "revise",
    // "删除":"删除",
    "设为默认": "Set as default",
    "默认地址": "Default",
    "请选择区号": "select an area code",
    "请选择": "Please select",
    // "设为默认":"设为默认",


























    'language': 'language',
    'myorder': 'My order',
    'VoucherShopping': 'Voucher shopping',
    "HuakangWine": "Huakang wine",
    "ClickToClaim": 'Click to claim',
    "SurprisePrice": "Surprise price",
    "SurpriseTtem": 'http://122.114.166.129:6257/832jmnbkgtjxq81iyt9v.png',
    "BuyNow": 'http://122.114.166.129:6257/0f1wg5sh8n52kmjh66q7.png',
    "tagImg": 'http://122.114.166.129:6257/oe9mlflkb6598878zf6s.png',
    "ReceivedSuccessfully": "Received successfully",
    "returnOfGoods": "30-day worry-free return",
    "drawback": "48-hour quick refund",
    "franking": "Free postage above 88 yuan",
    'empty': 'absolutely empty~',
    "toAdd": 'Go and add something',
    "TickAgreement": "Please tick User Agreement",
    "goShop": 'Go shopping',
    "edit": 'edit',
    "accomplish": 'complete',
    "delete": 'complete',
    "placeAnOrder": 'place an order',
    "Loading": 'Loading...',
    "SetUp": 'set up',
    "ToBePaid": "To be paid",
    "ToBeShipped": "To be shipped",
    "GoodsToBeReceived": "Goods to be received",
    "ToBeEvaluated": "To be evaluated",
    "Completed": "Completed",
    "AfterSales": "After sales",
    "MyService": "My service",
    "coupon": "coupon",
    "MyCollection": "My collection",
    "MyAddress": "My address",
    "ContactCustomerService": "Contact customer service",
    "Multilingual": "Multilingual",
    "UserServiceAgreement": "User Service Agreement",
    "PrivacyAgreement": "Privacy Agreement",
    "AboutUs": "About us",
    "AccountCancellation": "Account cancellation",
    "LogOut": "Log out",
    "LogOutSure": "Are you sure you want to log out?",
    "AccountCancellationSure": "Are you sure you want to cancel the account?",
    "save": "preservation",
    "cancel": "cancel",
    "PleaseSelect": 'Please select',
    "affirm": "affirm",
    "SelectState": "Select Province / Market / Distinguish",
    "SelectState1": "Province, city, district and county",
    "place1": 'Name',
    "place2": "Phone",
    "place3": 'Street number, floor room number and other information',
    "place31": 'Detailed address, such as street, building number, etc',
    "tip0": 'full name',
    "tip1": 'Telephone',
    "tip11": 'phone number',
    "tip2": 'region',
    "tip3": 'address',
    "tip4": 'Set as default shipping address',
    "SavedSuccessfully": "Saved successfully",
    "RefundGoods": "Refund goods",
    "orderDetails": "order details",
    "TotalPriceOfGoods": "Total price of goods",
    "element": "Yuan",
    "freight": "transportation expenses",
    "Discount": "Discount",
    "ActualPaymentOfOrder": "Actual payment of order",
    "operation": "operation",
    "RefundType": "Refund type",
    "RefundReason": "Refund reason",
    "pleaseEnter": "Please enter",
    "refundAmount": "refund amount",
    "payment-freight": "It cannot be modified, that is, order actual payment - freight",
    "RefundInstructions": "Refund instructions",
    "UploadVoucher": "Upload voucher",
    "UploadUpToThree": "Upload up to three",
    "after-salesService": "Apply for after-sales service",
    "notReceived": "Refund of goods not received",
    "RefundWithoutReturn": "Refund without return",
    "returnRefund": "Return refund",
    "refundType": "Please select the refund type",
    "refundReason": "Please enter the refund reason",
    "Submitting": "Submitting...",
    "ApplyAfter-salesSuccess": "Successful application for after-sales service",
    "RefundDetails": "Refund details",
    "After-salesStatus": "After-sales status",
    "ApplicationTime": "Application time",
    "ServiceNo": "Service No",
    "paymentOfOrder-freight": "That is, order actual payment - freight",
    "NotApplied": "Not applied",
    "AppliedToBeReviewed": "Applied, to be reviewed",
    "ApprovedToBeRefunded": "Approved, to be refunded",
    "RefundSucceeded": "Refund succeeded",
    "FailedToPassTheReviewRejected": "Failed to pass the review, rejected",
    "InApplication": "In application",
    "Processing": "Processing",
    "Rejected": "Rejected",
    "NoMore": "No more",
    "After-saleNo": "After-sale No",
    "Items": "Items",
    "RefundAmountApplied": "Refund amount applied",
    "WithdrawalOfApplication": "Withdrawal of application",
    "comitSure": "Are you sure you want to withdraw the application?",
    "whole": "whole",
    "HavePicture": "Have a picture",
    "MerchantReply": "Merchant reply",
    "FromReceipt": "From the date of receipt",
    "inDays": "Available in days",
    "full": "full",
    "MetaUse": "Meta use",
    "GetItNow": "Get it now",
    'sure': 'determine',
    "UseNow": "Use now",
    "score": "score",
    "ImageUpload": "Image upload",
    "publish": "publish",
    "VisibleToAll": "After the message is filtered, it will be visible to everyone",
    "perfectContentment": "perfect contentment",
    "uploading": "Uploading...",
    "UploadSucceeded": "Upload succeeded",
    "VeryPoor": "Very poor",
    "NotVerySatisfied": "Not very satisfied",
    "satisfied": "satisfied",
    "QuiteSatisfied": "Quite satisfied",
    "ComSucceeded": "Comment succeeded",
    'LoginRegistration': "Login/Registration",
    "default": "default",
    "noAddress": "The shipping address is not yet available~~~",
    "newlyBuild": "newly build",
    "deleteAddressSure": "Are you sure you want to delete the address?",
    "FromYuan": "From yuan",
    "noCollection": "No collection yet",
    "sureToDelete": "Are you sure to delete?",
    "deleteSuccess": "Delete succeeded",
    "ItsOver": "It's over",
    "notUsed": "not used",
    "Used": "Used",
    "Expired": "Expired",
    "VoucherCollectionCenter": "Voucher collection center",
    "OrderNo": "Order No",
    "common": "common",
    "PaidIn": "Paid-in",
    "OrderTime": "Order time",
    "OrderMessage": "Order message",
    "cancellationOfOrder": "cancellation of order",
    "ToPay": "To pay",
    "ToPay2": "To pay",
    "ConfirmEeceipt": "Confirm receipt",
    "DeleteOrder": "Delete order",
    "ApplyForRefund": "Apply for refund",
    "ProductInformation": "Product information",
    "ToEvaluate": "To evaluate",
    "BuyAgain": "Buy again",
    "TotalMerchandise": "Total merchandise",
    "OrderFreight": "Order freight",
    "OrderDiscount": "Order discount",
    "CourierServicesCompany": "Courier Services Company",
    "LogisticsBillNo": "Logistics bill No",
    "today": "today",
    "yesterday": "yesterday",
    "cancelOrder": "Are you sure you want to cancel this order?",
    "cancelSuccess": "Cancel order successfully",
    "ConfirmReceipt": "Confirm receipt?",
    "ConfirmSuccess": "Confirm receipt success",
    "deleteOrder": "Are you sure you want to delete this order?",
    "deletedSuccess": "Successfully deleted the order",
    " refundSure": "Are you sure you want to apply for refund?",
    "refund": "drawback",
    "RefundApplicationSucceeded": "Refund application succeeded",
    "noAddressToAdd": "There is no delivery address yet. Go to add it",
    "noCollections": "No coupons available",
    "fix": "fix",
    "ToMessage": "If necessary, please enter a message",
    "checkAdress": 'Please select the shipping address',
    "success": "success",
    "Selected": "Selected",
    "SelectSpecifications": "Select specifications",
    "appraise": "appraise",
    "ViewAll": "View All",
    "ProductIntroduction": "Product introduction",
    "commonProblem": "common problem",
    "price": "price",
    "GroupPurchaseVerticalReduction": "Group purchase and reduction",
    "num": "quantity",
    "addShopCart": "add to cart",
    "ItemSoldOut": "Item sold out",
    "JoinGroup": "Join the group purchase",
    "BuyNow2": "Buy Now",
    "selSpec": "Specification and quantity selection",
    "plaseSelSpec": "Please select the specification and quantity",
    "selFullspecification": "Please select the full specification",
    "NoInventory": "No inventory",
    "addSucess": "Successfully added",
    "noFinedGoods": "The product corresponding to the specification does not exist",
    "atypism": "The quantity and price of goods are inconsistent with the goods",
    "EnterPhoneNumber": "Enter phone number",
    "EnterPhoneNumberPlease": "Please enter your mobile number",
    "EnterVerificationCode": "Enter verification code",
    "EnterVerificationCodePlease": "Please enter the verification code",
    "GetVerificationCode": "Get verification code",
    "SignIn": "Sign in",
    "EnterName": "Please enter your name",
    "EnterTel": "Please enter your mobile number",
    "EnterRelTel": "Please enter the correct mobile number",
    "EnterCountry": "Please enter the province and city",
    "EnterAdress": "Please enter the detailed address",
    "InvoicedAmount": "Invoiced amount",
    "InvoiceType": "Invoice type",
    "HeadingType": "Heading type",
    "VAT": "VAT electronic ordinary invoice",
    "EnterpriseUnit": "Enterprise unit",
    "IndividualsOrInstitutions": "Individuals or institutions",
    "FillIn": "Fill in the name of the enterprise to be invoiced",
    "dutyParagraph": "duty paragraph",
    "FillIn2": "Fill in the taxpayer identification number",
    "BankOfDeposit": "Bank of deposit",
    "Optional": "Optional",
    "bankAccount": "bank account",
    "BusinessAddress": "Business address",
    "EnterpriseTelephone": "Enterprise telephone",
    "detailed": "detailed",
    "SelectHeader": "Select Header",
    "noInvoice": "You have no invoice title",
    "AddANew": "Add a new header",
    "InvoiceContent": "Invoice content",
    "willSisplay": "The invoice content will display detailed product name and price information",
    "invoice": "Invoice Header",
    "invoiceName": "Header name",
    "SetAsDefault": "Set as default",
    "consignee": "consignee",
    "InvoiceDetails": "Invoice details",
    "SuccessfulApplication": "Successful application",
    "Rejected2": "Rejected",
    "invoicePrice": "Invoice amount",
    "ApplicationDate": "Application date",
    "ModifyApplication": "Modify application",
    "CancellationOfApplication": "Cancellation of application",
    "ViewReasonsForRejection": "View reasons for rejection",
    "ViewInvoice": "View invoice",
    "ApplicationHistory": "Application History",
    "SaveToPhone": "Save to phone",
    "ApplyForInvoicing": "Apply for invoicing",
    "homePage": "home page",
    "ShoppingCart": "Shopping Cart",
    "personal": "personal",
    "classify": "classify",
    "ConfirmPayment": 'Confirm payment',
    "WeChatPayment": 'WeChat payment',
    "AlipayPayment": 'Alipay payment',
    "FollowSystem": 'Follow system',
    "SubmitApplication": 'Submit application',
}
export default en